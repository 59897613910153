<template>
  <div class="body-1">
    <v-card>
      <v-card-title>
        User List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="userList"
        :loading="isLoadingUsers"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
        :items-per-page="50"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.emailAddress="{item}">
          <router-link
            :to="{name: 'adminUserEdit2',params: { userId: item.id }}"
          >{{item.emailAddress}}</router-link>
        </template>
        <template v-slot:no-data>
          <td>No data found</td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

let headers = [
  {
    text: "First Name",
    value: "firstName",
    sortable: true
  },
  {
    text: "Last Name",
    value: "lastName",
    sortable: true
  },
  {
    text: "Email Address",
    value: "emailAddress",
    sortable: true
  },
  {
    text: "Phone Number",
    value: "phoneNumber"
  },
  {
    text: "Department Name",
    value: "departmentName",
    sortable: true
  }
];

export default {
  data() {
    return {
      search: "",
      headers: headers,
    };
  },
  computed: mapState({
    isLoadingUsers: state => state.admin.isLoadingUsers,
    userListTotalItems: state => state.admin.userListTotalItems,
    userList: state => state.admin.userList
  }),
  methods: {
    ...mapActions(["loadAdminUserList"])
  },
  created() {
    this.loadAdminUserList({
      pagination: {},
      search: this.search
    });
  }
};
</script>

